const API_URL = process.env.REACT_APP_API_URL;

export const getKbarDetails = async (serialNumber: string) => {
  const GET_KBAR_DETAILS = `${API_URL}/kbars?serialNumber=${serialNumber}`;
  const result = await fetch(GET_KBAR_DETAILS)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((userData) => {
      return userData;
    });
  return result;
};

const Disclaimer = () => {
  return (
    <div className="declaimer-container">
      <p>
        <span className="declaimer-title">Disclaimer: &nbsp;</span>
        By using KBAR, you are bound by the&nbsp;
        <a
          href="https://www.duboisgold.com/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
          className="customer-text-color text-decoration-underline fw-medium fs-6"
        >
          Terms and Conditions
        </a>
        &nbsp;as well as the&nbsp;
        <a
          href="https://www.duboisgold.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          className="customer-text-color text-decoration-underline fw-medium fs-6"
        >
          Privacy Policy
        </a>
        &nbsp;outlined on the DuBois website.&nbsp;The image displayed on this
        certificate serves as a representative likeness of the gold and may not
        depict the actual physical appearance of the product.
      </p>
    </div>
  );
};

export default Disclaimer;

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Certificate from "../component/Certificate";
import { getKbarDetails } from "./_requests";
import { kbarInitialValue } from "./_models";
import { Contract, ethers } from "ethers";
import { NETWORKS_INFO, SupportedChainId } from "../config/chains";
import ABI from "../config/ABI.json";
import moment from "moment";
import DuBois from "../assets/du-bois";
import Disclaimer from "../component/Disclaimer";
const CertificatePage = () => {
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const [data, setData] = useState<any>(kbarInitialValue);

  const getTokenOwner = async (tokenId: string, kbarDetails: any) => {
    const activeChain =
      process.env.REACT_APP_CHAIN_ENV === "mainnet"
        ? NETWORKS_INFO[SupportedChainId.ETHEREUM]
        : NETWORKS_INFO[SupportedChainId.SEPOLIA_ETHEREUM];
    const provider = new ethers.JsonRpcProvider(activeChain?.rpcUrl);
    if (contractAddress) {
      const contract = new Contract(contractAddress, ABI, provider);
      const ownerResponse = await contract.ownerOf(tokenId);
      setData({ ...kbarDetails, ownerAddress: ownerResponse });
    }
  };
  const getDetails = async () => {
    if (params?.serial_number) {
      setLoading(true);
      setImageLoading(true);
      const kbarDetails = await getKbarDetails(params?.serial_number);
      setData(kbarDetails);
      if (kbarDetails) {
        getTokenOwner(kbarDetails?.tokenId, kbarDetails);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, [params]);

  return (
    <>
      <div className="container">
        <div
          className={`certificate-section ${
            loading || imageLoading ? "sub-container" : ""
          }`}
        >
          <div className="certificate-section2">
            <div className="goldbar-image-section">
              {loading ? (
                <p className="skeleton skeleton-kbar-image" />
              ) : (
                <img
                  src={data?.image}
                  alt="gold-bar"
                  className="gold-bar"
                  onLoad={() => setImageLoading(false)}
                />
              )}
            </div>
            <Certificate loading={loading} data={data} />
          </div>
          <div className="owner-section">
            <div className="owner-info-before" />
            <div className="owner-info-after">
              {loading ? (
                <div className="owner-details ">
                  <p className="owner-id skeleton-info-text"></p>
                  <p className="owner-id skeleton-info-text"></p>
                  <p className="owner-details skeleton-info-text"></p>
                </div>
              ) : (
                <div className="owner-details">
                  <p className="owner-info-value">Currently held by</p>
                  <p className="owner-id owner-info-value">
                    {data?.ownerAddress || data?.receiver_address}
                  </p>
                  {data?.ownerAddress ? (
                    <p className="owner-info-value">{`on Ethereum as at ${moment()
                      .utc()
                      .format("hh:mma (UTC), DD MMMM YYYY")
                      .toString()}`}</p>
                  ) : (
                    <p className="owner-info-value">
                      <br />
                    </p>
                  )}
                </div>
              )}
              <DuBois />
            </div>
          </div>
          <div className="content-separator" />
          <Disclaimer />
        </div>
      </div>
    </>
  );
};

export default CertificatePage;

const Certificate = ({ loading, data }: any) => {
  const { brand, serial_number, fineness, location, gross_weight_kg } = data;

  return (
    <>
      {loading ? (
        <div className="certificate-card ">
          <h1 className="title skeleton skeleton-title" />
          <div className="info-section">
            <div className="label-section">
              <label>Brand</label>
              <label>Serial No</label>
              <label>Fineness</label>
              <label>Weight</label>
              <label>Location</label>
            </div>
            <div className="line-separator" />
            <div className="label-section">
              <p className="skeleton skeleton-text" />
              <p className="skeleton skeleton-text" />
              <p className="skeleton skeleton-text" />
              <p className="skeleton skeleton-text" />
              <p className="skeleton skeleton-text" />
            </div>
          </div>
        </div>
      ) : (
        <div className="certificate-card">
          <h1 className="title">Certificate of Title</h1>
          <div className="info-section">
            <div className="label-section">
              <label>Brand</label>
              <label>Serial No</label>
              <label>Fineness</label>
              <label>Weight</label>
              <label>Location</label>
            </div>
            <div className="line-separator" />
            <div className="label-section">
              <p className="certificate-value">{brand ? brand : ""}</p>
              <p className="certificate-value">
                {serial_number ? serial_number : ""}
              </p>
              <p className="certificate-value">{fineness ? fineness : ""}</p>
              <p className="certificate-value">
                {gross_weight_kg ? gross_weight_kg : ""}
              </p>
              <p className="certificate-value">{location ? location : ""}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Certificate;

export type IKBARType = {
  id: string;
  title: string;
  tokenUri: string | null;
  tokenId: string | null;
  serial_number: string;
  description: string;
  image: string;
  format: string;
  brand: string;
  fineness: string;
  gross_weight_kg: string | null;
  status: string;
  location: string | null;
  receiver_address: string | null;
  createdAt: Date | string;
  ownerAddress: string | null;
  txHash: string | null;
  certificate: string | null;
  fine_weight_kg: string | null;
};

export const kbarInitialValue: IKBARType = {
  id: "",
  title: "",
  tokenUri: "",
  tokenId: "",
  serial_number: "",
  description: "",
  image: "",
  format: "",
  brand: "",
  fineness: "",
  gross_weight_kg: "",
  status: "",
  location: "",
  receiver_address: "",
  createdAt: "",
  ownerAddress: "",
  txHash: "",
  certificate: "",
  fine_weight_kg: "",
};

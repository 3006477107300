export enum SupportedChainId {
  ETHEREUM = "0x1",
  SEPOLIA_ETHEREUM = "0xaa36a7",
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.SEPOLIA_ETHEREUM]: "Sepolia",
  [SupportedChainId.ETHEREUM]: "Ethereum",
};

export const EVM_CHAIN_IDS = [
  SupportedChainId.ETHEREUM,
  SupportedChainId.SEPOLIA_ETHEREUM,
] as const;

export const NETWORKS_INFO = {
  [SupportedChainId.SEPOLIA_ETHEREUM]: {
    id: SupportedChainId.SEPOLIA_ETHEREUM,
    token: "ETH",
    label: "Sepolia Ethereum Testnnet",
    rpcUrl: `https://practical-frosty-mound.ethereum-sepolia.quiknode.pro/ad04b4080e7d08cf3a7838d392da98141bea7d7d`,
  },
  [SupportedChainId.ETHEREUM]: {
    id: SupportedChainId.ETHEREUM,
    token: "ETH",
    label: "Ethereum Mainnet",
    rpcUrl: `https://empty-sly-owl.quiknode.pro/d82087e9cf0b88b067f6629b11dcb628f97e07a8`,
  },
};

import React from "react";

const DuBois = () => (
  <svg
    width="141"
    height="44"
    viewBox="0 0 141 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_866_857)">
      <path
        d="M36.9591 6.74047L41.5724 14.8073L39.2342 21.5451L30.0103 5.41412L36.9591 6.74047Z"
        fill="#BFA571"
      />
      <path
        d="M4.6133 6.74047L0 14.8073L2.33818 21.5451L11.5622 5.41412L4.6133 6.74047Z"
        fill="#BFA571"
      />
      <path
        d="M16.1727 0H25.3993L30.01 5.41413H11.562L16.1727 0Z"
        fill="#BFA571"
      />
      <path
        d="M4.6133 36.3524L0 28.2856L2.33818 21.5451L11.5622 37.6787L4.6133 36.3524Z"
        fill="#BFA571"
      />
      <path
        d="M36.9591 36.3524L41.5724 28.2856L39.2342 21.5451L30.0103 37.6787L36.9591 36.3524Z"
        fill="#BFA571"
      />
      <path
        d="M25.3993 43.0928H16.1727L11.562 37.6787H30.01L25.3993 43.0928Z"
        fill="#BFA571"
      />
      <path
        d="M52.1021 31.3441V11.7461H59.1402C65.6057 11.7461 69.0368 15.0487 69.063 21.2984C69.0762 24.4551 68.225 26.9247 66.5331 28.641C64.765 30.4342 62.1326 31.3441 58.7067 31.3441H52.1047H52.1021ZM55.7275 28.4262H58.8407C63.1204 28.4262 65.385 25.9698 65.385 21.3249C65.385 16.6801 63.1756 14.7197 58.4335 14.7197H55.7275V28.4262Z"
        fill="#05171D"
      />
      <path
        d="M84.7422 17.3538H81.3899V24.9273C81.3899 28.32 79.6665 29.0336 78.2215 29.0336C76.2722 29.0336 75.3264 27.8081 75.3264 25.2854V17.3538H71.9741V26.2456C71.9741 29.8666 73.6371 31.7022 76.9158 31.7022C78.852 31.7022 80.3364 30.9701 81.2007 29.6411H81.6263C81.5974 30.0416 81.5817 30.2247 81.5817 31.0258V31.3441H84.7448V17.3512L84.7422 17.3538Z"
        fill="#05171D"
      />
      <path
        d="M88.0366 31.3441V11.7461H95.7001C99.9193 11.7461 102.058 13.3244 102.058 16.4334C102.058 20.1949 98.8316 20.9482 98.6845 21.0092C98.8395 21.0517 102.927 21.7016 102.927 25.8053C102.927 29.3785 100.418 31.3441 95.8629 31.3441H88.0366ZM91.4966 28.5349H95.4006C97.9542 28.5349 99.2494 27.4951 99.2494 25.4472C99.2494 23.5426 97.9095 22.577 95.2666 22.577H91.4992V28.5349H91.4966ZM91.4966 19.9057H94.9382C97.8727 19.9057 98.4875 18.4202 98.4875 17.1735C98.4875 15.4227 97.3683 14.6083 94.9645 14.6083H91.4966V19.9057Z"
        fill="#05171D"
      />
      <path
        d="M122.208 31.3441V17.3512H125.615V31.3441H122.208ZM122.208 14.9107V11.7461H125.615V14.9107H122.208Z"
        fill="#05171D"
      />
      <path
        d="M134.34 31.7022C131.019 31.7022 128.847 30.3653 128.198 27.9301L131.177 27.2563C131.758 28.7286 132.819 29.4156 134.506 29.4156C136.192 29.4156 137.101 28.7259 137.101 27.6171C137.101 26.2483 135.701 26.0281 132.822 25.2774C130.092 24.5532 128.613 23.6964 128.613 21.2427C128.613 18.6457 130.862 16.9666 134.343 16.9666C137.437 16.9666 139.463 18.2239 140.078 20.5158L137.167 21.1366C136.76 19.9376 135.769 19.3062 134.288 19.3062C132.898 19.3062 131.962 19.9747 131.962 20.9668C131.962 22.3886 133.373 22.5743 136 23.2242C138.783 23.906 140.506 24.8291 140.506 27.312C140.506 30.0575 138.202 31.6969 134.34 31.6969V31.7022Z"
        fill="#05171D"
      />
      <path
        d="M112.422 16.94C108.09 16.94 105.11 19.9853 105.11 24.3463C105.11 28.7073 108.048 31.6996 112.422 31.6996C116.796 31.6996 119.733 28.7445 119.733 24.3463C119.733 19.9482 116.757 16.94 112.422 16.94ZM112.422 29.0575C110.031 29.0575 108.547 27.2643 108.547 24.3755C108.547 21.4867 110.068 19.6113 112.422 19.6113C114.776 19.6113 116.297 21.4814 116.297 24.3755C116.297 27.2696 114.812 29.0575 112.422 29.0575Z"
        fill="#05171D"
      />
    </g>
    <defs>
      <clipPath id="clip0_866_857">
        <rect width="140.506" height="43.0928" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DuBois;

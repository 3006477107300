import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import CertificatePage from "./pages/CertificatePage";
import "./index.css";
const CustomApp = () => {
  return <Outlet />;
};

function App() {
  const { PUBLIC_URL } = process.env;
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<CustomApp />}>
          <Route path="/:serial_number" element={<CertificatePage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
